import Header from "components/personal/Header";

import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect } from "react";
import Meta from "components/navigation/Meta";
import CTA from "components/personal/CTA";
import Search from "components/personal/Search";

function Personal() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<Meta title="Personal" />
			<div className="relative bg-hero-pattern bg-cover bg-center bg-no-repeat">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<div data-scroll-section className="pt-20">
					<Header />
					<CTA />
					<Search />
				</div>
				<Footer />
			</div>
		</Layout>
	);
}
export default Personal;
