import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import Error404 from 'containers/errors/Error404';
import Home from 'containers/pages/Home';
import Cases from 'containers/pages/Cases';

import Services from 'containers/pages/Services';
import About from 'containers/pages/About';
import Careers from 'containers/pages/Careers';
import Blog from 'containers/pages/Blog';
import Contact from 'containers/pages/Contact';
import GrandesBases from 'containers/pages/GrandesBases';

import { AnimatePresence } from 'framer-motion'
import Category from 'containers/pages/Category';
import Search from 'containers/pages/Search';
import PostDetail from 'containers/pages/PostDetail';

import Register from 'containers/pages/Register';
import Profile from 'containers/pages/Profile';
import Personal from 'containers/pages/Personal';
import Mujeres_Salud from 'containers/pages/directive/Mujeres_Salud';
import Investigacion_Docencia from 'containers/pages/directive/Investigacion_Docencia';
import RequerimientoSalud from 'containers/pages/directive/RequerimientoSalud';
import PoliticoSalud from 'containers/pages/directive/PoliticoSalud';
function AnimatedRoutes(){

    const location = useLocation()

    return(
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                    {/* Error Display */}
                    <Route path="*" element={<Error404 />} />

                    {/* Home Display */}
                    <Route path="/" element={<Home />} />
                    <Route path="/casos" element={<Cases />} />
                    <Route path="/servicios" element={<Services />} />
                    <Route path="/nosotros" element={<About />} />
                    <Route path="/carreras" element={<Careers />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:slug" element={<PostDetail />} />
                    <Route path="/s=:term" element={<Search />} />
                    <Route path="/category/:slug" element={<Category />} />
                    <Route path="/contacto" element={<Contact />} />
                    <Route path="/grandes-bases" element={<GrandesBases />} />
										{/* direcciones */}
										<Route path='/mujeres_salud' element={<Mujeres_Salud />} />
										<Route path='/investigacion-docencia' element={<Investigacion_Docencia />} />
										<Route path='/requerimientos-salud-caracas' element={<RequerimientoSalud />} />
										<Route path='/politico-salud' element={<PoliticoSalud />} />
										<Route path="/registro" element={<Register />} />
										<Route path='/pacificador/:nuip' element={<Profile />} />
										<Route path='/personal' element={<Personal />} />
								</Routes>
        </AnimatePresence>
    )
}
export default AnimatedRoutes
