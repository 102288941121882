import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { get_categories } from "redux/actions/categories/categories";
import { connect } from "react-redux";
import { get_blog_list, get_blog_list_page } from "redux/actions/blog/blog";
import CategoriesHeader from "components/blog/CategoriesHeader";
import BlogList from "components/blog/BlogList";
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid'
import Meta from "components/navigation/Meta";

function Blog({
	get_categories,
	categories,
	get_blog_list,
	get_blog_list_page,
	posts,
	count,
	next,
	previous,
}) {
	useEffect(() => {
		window.scrollTo(0, 0);
		get_categories();
		get_blog_list();
	}, []);


	return (
		<Layout>
			<Meta title="Blog" />
			<header className="flex justify-center">
				<Navbar />
			</header>
			{
				posts && posts.length > 0 ? (
					<div className="pt-24 ">
				<CategoriesHeader categories={categories && categories} />

				<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
					{/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
					<div className="mx-auto max-w-6xl my-10">
						{/* Content goes here */}
						<BlogList
							posts={posts && posts}
							get_blog_list_page={get_blog_list_page}
							count={count && count}
						/>
					</div>
				</div>
			</div>
				) : (
					<div className="pt-24 ">
					<div className="flex flex-col items-center justify-center h-screen bg-gray-700">
						<CheckCircleIcon className="h-20 w-20 text-green-500" />
						<h1 className="text-3xl font-semibold text-white">No hay publicaciones</h1>
						<p className="text-white">No hay publicaciones en este momento</p>
					</div>
					</div>
				)
			}
			<Footer />
		</Layout>
	);
}
const mapStateToProps = (state) => ({
	categories: state.categories.categories,
	posts: state.blog.blog_list,
	count: state.blog.count,
	next: state.blog.next,
	previous: state.blog.previous,
});

export default connect(mapStateToProps, {
	get_categories,
	get_blog_list,
	get_blog_list_page,
})(Blog);
