import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Header from "components/services/Header";
import ServicesList from "components/services/ServicesList";
import Layout from "hocs/layouts/Layout";
import coding_img from "assets/img/services/coding.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
const posts_software = [
	{
		title: "Boost your conversion rate",
		img: coding_img,
		href: "#",
		category: { name: "Article", href: "#" },
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
		date: "Mar 16, 2020",
		datetime: "2020-03-16",
		imageUrl:
			"https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
		readingTime: "6 min",
		author: {
			name: "Roel Aufderehar",
			href: "#",
			imageUrl:
				"https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
		},
	},
	{
		title: "How to use search engine optimization to drive sales",
		href: "#",
		img: coding_img,
		category: { name: "Video", href: "#" },
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
		date: "Mar 10, 2020",
		datetime: "2020-03-10",
		imageUrl:
			"https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
		readingTime: "4 min",
		author: {
			name: "Brenna Goyette",
			href: "#",
			imageUrl:
				"https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
		},
	},
	{
		title: "Improve your customer experience",
		href: "#",
		img: coding_img,
		category: { name: "Case Study", href: "#" },
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
		date: "Feb 12, 2020",
		datetime: "2020-02-12",
		imageUrl:
			"https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
		readingTime: "11 min",
		author: {
			name: "Daniela Metz",
			href: "#",
			imageUrl:
				"https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
		},
	},
];

const posts_design = [
	{
		title: "Boost your conversion rate",
		img: coding_img,
		href: "#",
		category: { name: "Article", href: "#" },
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
		date: "Mar 16, 2020",
		datetime: "2020-03-16",
		imageUrl:
			"https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
		readingTime: "6 min",
		author: {
			name: "Roel Aufderehar",
			href: "#",
			imageUrl:
				"https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
		},
	},
	{
		title: "How to use search engine optimization to drive sales",
		href: "#",
		img: coding_img,
		category: { name: "Video", href: "#" },
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
		date: "Mar 10, 2020",
		datetime: "2020-03-10",
		imageUrl:
			"https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
		readingTime: "4 min",
		author: {
			name: "Brenna Goyette",
			href: "#",
			imageUrl:
				"https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
		},
	},
	{
		title: "Improve your customer experience",
		href: "#",
		img: coding_img,
		category: { name: "Case Study", href: "#" },
		description:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
		date: "Feb 12, 2020",
		datetime: "2020-02-12",
		imageUrl:
			"https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
		readingTime: "11 min",
		author: {
			name: "Daniela Metz",
			href: "#",
			imageUrl:
				"https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
		},
	},
];

function Services() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<Helmet>
				<title>Estado Mayor de Salud Caracas | Servicios</title>
				<meta
					name="description"
					content="Agencia de software y marketing digital. Servicios de creacion de pagina web y desarrollo de aplicaciones."
				/>
				<meta
					name="keywords"
					content="agencia de software, agencia de marketing, creacion de pagina web"
				/>
				<meta name="robots" content="all" />
				<link rel="canonical" href="https://fundacionarduvi.org/" />
				<meta name="author" content="Fundación Arduvi" />
				<meta name="publisher" content="Fundación Arduvi" />

				{/* Social Media Tags */}
				<meta property="og:title" content="Fundación Arduvi | Inclusión con Amor" />
				<meta
					property="og:description"
					content="Agencia de software y marketing digital. Servicios de creacion de pagina web y desarrollo de aplicaciones."
				/>
				<meta property="og:url" content="https://fundacionarduvi.org/" />
				<meta
					property="og:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>

				<meta name="twitter:title" content="Fundación Arduvi | Inclusión con Amor" />
				<meta
					name="twitter:description"
					content="Agencia de software y marketing digital. Servicios de creacion de pagina web y desarrollo de aplicaciones."
				/>
				<meta
					name="twitter:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>
			<header className="flex justify-center">
				<Navbar />
			</header>
			<div className="pt-28">
				<Header />
				<div className="py-12 bg-gray-50"></div>
				<ServicesList
					posts={posts_software}
					section_title={"Software and Product Development"}
				/>
				<ServicesList posts={posts_design} section_title={"Design Services"} />
			</div>
			<Footer />
		</Layout>
	);
}
export default Services;
