import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import store from './store';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import AnimatedRoutes from 'Routes';


function App() {

  return (
    <HelmetProvider>
      <Helmet>
        <title>Estado Mayor de Salud Caracas | Inicio</title>
        <meta name="description" content="Movimiento por la paz y la vida." />
        <meta name="keywords" content='agencia de software, agencia de marketing, creacion de pagina web' />
        <meta name="robots" content='all' />
        <link rel="canonical" href="https://mpazyvida.org" />
        <meta name="author" content='Fundacion Movimiento por la paz y la vida' />
        <meta name="publisher" content='Fundacion Movimiento por la paz y la vida' />

        {/* Social Media Tags */}
        <meta property="og:title" content='Fundacion Movimiento por la paz y la vida | Caracas - Venezuela' />
        <meta property="og:description" content='Movimiento por la paz y la vida.' />
        <meta property="og:url" content="https://mpazyvida.org" />
        <meta property="og:image" content='https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg' />

        <meta name="twitter:title" content='Fundacion Movimiento por la paz y la vida | Caracas - Venezuela' />
        <meta
            name="twitter:description"
            content='Movimiento por la paz y la vida.'
        />
        <meta name="twitter:image" content='https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Provider store={store}>
        <Router>

            <AnimatedRoutes/>
						<ToastContainer/>
        </Router>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
