import { connect } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
// import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useState, Fragment, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
// import loading_dots from "assets/img/loading-dots.gif";
// import logo_boomslag from "assets/img/boomslag-black.png";
import DotLoader from "react-spinners/DotLoader";
import logo_full from "assets/img/logo-full.png";

const solutions = [
	{
		name: "Inicio",
		description: "",
		href: "/",
	},
	{
		name: "Nosotros",
		description: "",
		href: "/nosotros",
	},

	{
		name: "Noticias",
		description: "",
		href: "/#seccion-3",
	},
	{
		name: "Personal",
		description: "",
		href: "/personal",
	}
	// {
	// 	name: "Contacto",
	// 	description: "",
	// 	href: "#seccion-4",
	// 	icon: IconThree,
	// },
];

function Navbar() {
	const [loading, setLoading] = useState(true);

	window.onscroll = function () {
		scrollFunction();
	};

	function scrollFunction() {
		if (document.getElementById("navbar")) {
			if (
				document.body.scrollTop > 50 ||
				document.documentElement.scrollTop > 50
			) {
				document
					.getElementById("navbar")
					.classList.add("shadow-navbar", "backdrop-blur-md", "bg-gray-800/40");
			} else {
				document
					.getElementById("navbar")
					.classList.remove(
						"shadow-navbar",
						"backdrop-blur-md",
						"bg-gray-800/40"
					);
			}
		}
	}

	return (
		<nav
			data-scroll
			data-scroll-id="hey"
			id="navbar"
			className="py-4 top-4 transition duration-100 ease-in-out z-40 fixed rounded-full w-11/12 max-w-6xl "
		>
			<div className="px-2 xl:px-4">
				<div className="-ml-4 -mt-2 hidden lg:flex flex-wrap items-center justify-between sm:flex-nowrap md:px-4 px-2">
					<Link to="/" className="ml-4 mt-2">
						<img
							src={logo_full}
							width={120}
							height={120}
							className=""
							alt="Estado Mayor de Salud "
						/>
					</Link>
					<div className="ml-4 mt-2 flex-shrink-0">
						<Link
							to="/"
							className="text-lg inline-flex font-semibold leading-6 text-black border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4"
						>
							Inicio
						</Link>
						<Link
							to="/nosotros"
							className="text-lg inline-flex font-semibold leading-6 text-black border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4"
						>
							Nosotros
						</Link>

						<NavLink
							to="/blog"
							className="text-lg inline-flex font-semibold leading-6 text-black border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4"
						>
							Noticias
						</NavLink>
						<NavLink
							to="/personal"
							className="text-lg inline-flex font-semibold leading-6 text-black border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4"
						>
							Personal
						</NavLink>
						{/* <a
							href="#seccion-4"
							className="text-lg inline-flex font-semibold leading-6 text-gray-900 border-b-2 border-transparent hover:border-red-500 transition duration-300 ease-in-out mx-4"
						>
							Contacto
						</a> */}
						<a
							href="#"
							className="inline-flex ml-6 items-center rounded-md border border-transparent bg-red-500 px-6 py-1.5 text-base font-medium text-white shadow-sm hover:bg-blue-button transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
							target="_blank" // opcional, para abrir en una nueva pestaña
							rel="noopener noreferrer" // importante por razones de seguridad cuando se usa target="_blank"
						>
							Ingresa
							<DotLoader
								className="ml-3 -mr-1 h-5 w-5"
								loading={loading}
								size={20}
								color="#f2f2f2"
							/>
						</a>
					</div>
				</div>
				<div className="-ml-4 -mt-2 lg:hidden flex flex-wrap items-center justify-between sm:flex-nowrap md:px-4 px-2">
					<Link to="/" className="ml-4 mt-3">
						<img
							src={logo_full}
							width={120}
							height={120}
							className=""
							alt="M. paz y vida"
						/>
					</Link>
					<div className="ml-4 mt-2 flex-shrink-0">
						<Popover className="relative">
							{({ open }) => (
								<>
									<Popover.Button
										className={`
                                ${open ? "" : "text-opacity-90"}
                                focus:ring-none focus:outline-none`}
									>
										{open ? (
											<i className="bx bx-x text-4xl mt-1"></i>
										) : (
											<i className="bx bx-menu text-4xl mt-1"></i>
										)}
									</Popover.Button>

									<Transition
										as={Fragment}
										enter="transition ease-out duration-200"
										enterFrom="opacity-0 translate-y-1"
										enterTo="opacity-100 translate-y-0"
										leave="transition ease-in duration-150"
										leaveFrom="opacity-100 translate-y-0"
										leaveTo="opacity-0 translate-y-1"
									>
										<Popover.Panel className="absolute -left-32 z-10 mt-5 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
											<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
												<div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
													{solutions.map((item) => (
														<a
															key={item.name}
															href={item.href}
															className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-50"
														>
															<div className="ml-4">
																<p className="text-sm font-medium text-gray-900">
																	{item.name}
																</p>
																<p className="text-sm text-gray-500">
																	{item.description}
																</p>
															</div>
														</a>
													))}
												</div>
											</div>
										</Popover.Panel>
									</Transition>
								</>
							)}
						</Popover>
					</div>
				</div>
			</div>
		</nav>
	);
}
export default Navbar;
