
import Header from "components/bases/Header";
import Footer from "components/navigation/Footer";
import Meta from "components/navigation/Meta";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect } from "react";

import { Link } from "react-router-dom";

function About() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<Meta title="Grandes Bases" />

			<div className="">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<div className="pt-28">
					<Header />

					{/* <Features /> */}
					{/* <div className="bg-gray-800">
						<div className="mx-auto lg:mx-12 max-w-full py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
							<h2 className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
								<span className="block">¿Te gutaría ser un pacificador?</span>
							</h2>
							<div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
								<div className="inline-flex rounded-md shadow">
									<Link
										to="/contacto"
										className="inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-button px-5 py-3 text-base font-medium text-white hover:bg-blue-button transition duration-300 ease-in-out"
									>
										Regístrate
									</Link>
								</div>
							</div>
						</div>
					</div> */}
				</div>
			</div>
			<Footer />
		</Layout>
	);
}
export default About;
