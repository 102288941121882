
import Header from "components/directive/Header";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import CTA from "components/home/CTA";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Features from "components/directive/Features";
import hero_bg from "../../../assets/img/item-3.png";
import Meta from "components/navigation/Meta";

function RequerimientoSalud() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const features = [
		{
			description: 'Sensibilizar y concienciar a la sociedad sobre la situación de las personas mayores en vulnerabilidad y sus necesidades específicas.'
		},{
			description: 'Ofrecer servicios de atención integral a las personas mayores en vulnerabilidad, brindándoles apoyo psicosocial, jurídico, económico y sanitario.'
		},{
			description: 'Coordinar, brindar o gestionar espacios de acogida, convivencia y recreación a las personas mayores en vulnerabilidad, favoreciendo su integración social y su calidad de vida.'
		},{
			description: 'Promover la participación activa y el protagonismo de las personas mayores en vulnerabilidad, reconociendo y valorando su experiencia, sabiduría y aportes a la sociedad.'
		},{
			description: 'Impulsar la formación, la capacitación y el acceso a las oportunidades de las personas mayores en vulnerabilidad, potenciando sus capacidades, habilidades y proyectos de vida.'
		},{
			description: 'Fomentar el desarrollo de redes de apoyo y solidaridad entre las personas mayores en vulnerabilidad, fortaleciendo sus vínculos familiares, comunitarios y sociales.'
		},{
			description: 'Facilitar el acceso a la información, la comunicación y la tecnología a las personas mayores en vulnerabilidad, reduciendo la brecha digital y ampliando sus posibilidades de expresión y conexión.'
		},{
			description: 'Estimular la expresión artística, cultural, deportiva y espiritual de las personas mayores en vulnerabilidad, como medio de liberación, comunicación, autoestima y bienestar.'
		},{
			description: 'Realizar acciones de incidencia política, social y legal, para exigir el cumplimiento y la ampliación de los derechos de las personas mayores en vulnerabilidad, y para influir en la elaboración, implementación y evaluación de las políticas públicas de envejecimiento.'
		},{
			description: 'Participar activamente en los procesos electorales, tanto como candidatos como como electores, para aumentar la representación y el liderazgo de las personas mayores en vulnerabilidad en los espacios de toma de decisiones.'
		}		]
	const mision = "Adulto Mayor por la Paz y la Vida es defender y promover los derechos, la dignidad y el bienestar de las personas mayores, especialmente de aquellas que se encuentran en situación de vulnerabilidad por razones de pobreza, discriminación, violencia o abandono."
	return (
		<Layout>
			<Meta title="Requerimientos de Salud"/>

			<div className="relative bg-hero-pattern bg-cover bg-center bg-no-repeat">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<div data-scroll-section className="pt-20">
					<Header hero_bg={hero_bg} title={"Adulto Mayor por la paz y la vida"} mission={mision}/>
				</div>
				<CTA />
				<Features title={"Adulto Mayor por la Paz y la Vida"} features={features}/>
			</div>
			<Footer />
		</Layout>
	);
}
export default RequerimientoSalud;
