import { CheckIcon } from "@heroicons/react/24/outline";

const features = [
  { name: "Controlar y vigilar", description: "Controlar y vigilar el funcionamiento de todos los centros de salud adscritos al Ministerio Popular para la Salud, con el fin de garantizar los recursos y su operatividad permanente." },
  { name: "Planificar y coordinar", description: "Planificar, coordinar, integrar, normalizar, viabilizar y ejecutar acciones y actividades del equipo de la red de salud hospitalaria y ambulatoria a fin de cumplir con los fines de desarrollo económico y social enmarcados dentro del Plan Estratégico Simón Bolívar 2013-2019." },
  { name: "Supervisar funcionamiento", description: "Controlar y supervisar el funcionamiento de todas y cada una de las Direcciones, Coordinaciones y Unidades adscritas a esta Dirección de Salud del Distrito Capital, a fin de garantizar el derecho a la salud y calidad de vida de la población." },
  { name: "Aprobar procesos", description: "Aprobar los procesos administrativos y financieros relacionados con los centros de salud y dependencias adscritas a la Dirección de Salud del Distrito Capital." },
  { name: "Integración de trabajadores", description: "Establecer los mecanismos que permitan la integración de los trabajadores y trabajadoras para despertar el sentido de pertenencia con la Dirección de Salud del Distrito Capital." },
];

export default function FeaturesLine() {
  return (
    <div className="">
      <div className="mx-auto lg:mx-12 max-w-full py-16 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
        <div className="lg:col-span-3">
          <h2 className="text-xl font-semibold text-yellow-button">
            Objetivos Específicos del Estado Mayor de Salud de Caracas
          </h2>
          <p className="mt-2 text-base font-bold tracking-tight text-black">
            Los objetivos específicos del Estado Mayor de Salud de Caracas se detallan a continuación:
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-3">
          <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg font-medium leading-6 text-black">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-black">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
