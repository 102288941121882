import Footer from "components/navigation/Footer";
import Meta from "components/navigation/Meta";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { Link } from "react-router-dom";

function Error404() {
	return (
		<Layout>
			<Meta title="404" />
			<header className="flex justify-center">
				<Navbar />
			</header>
			<div className="pt-28 ">
				<div className="relative bg-gray-400">
					<div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
						<div className="bg-gray-500 py-16 px-4 sm:px-6 lg:col-span-5 lg:px-8 lg:py-24 xl:pr-12">
							<div className="mx-auto max-w-lg text-center">
								<h1 className="text-6xl font-extrabold text-gray-900 sm:text-8xl">
									404
								</h1>
								<h2 className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
									Página No Encontrada
								</h2>
								<p className="mt-4 text-lg leading-6 text-gray-500">
									Lo sentimos, no pudimos encontrar la página que estás
									buscando.
								</p>
								<p className="mt-6">
									<Link
										to="/"
										className="text-lg font-medium text-blue-600 hover:text-blue-500"
									>
										Regresa al inicio
									</Link>{" "}
									o utiliza el menú para continuar navegando.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</Layout>
	);
}

export default Error404;
