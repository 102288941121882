import React from "react";
import video_1 from "../../assets/vid/1.mp4";
import video_2 from "../../assets/vid/2.mp4";
import video_3 from "../../assets/vid/3.mp4";
const posts = [
	{
		title: "La salud en el Barrio.",

		videoUrl: video_1,
	},
	{
		title: "La salud en el Barrio.",

		videoUrl: video_2,
	},
	{
		title: "La salud en el Barrio.",

		videoUrl: video_3,
	},
];

export default function UseCases() {
	return (
		<div className="relative px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
			<div className="absolute inset-0">
				<div className="h-1/3  sm:h-2/3" />
			</div>
			<div className="relative mx-auto lg:mx-12 max-w-full">
				<div className="text-center">
					<h2 className="text-3xl font-bold tracking-tight text-yellow-button sm:text-4xl">
						Multimedia
					</h2>
				</div>
				<div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
					{posts.map((post, index) => (
						<div
							key={index}
							className="flex flex-col overflow-hidden rounded-lg shadow-lg"
						>
							<div className="flex-shrink-0">
								<video className="h-64 w-full object-cover" controls>
									<source src={post.videoUrl} type="video/mp4" />
									Tu navegador no soporta videos.
								</video>
							</div>
							<div className="flex flex-1 flex-col justify-between bg-gray-700 p-6">
								<div className="flex-1">
									<div className="flex flex-1 flex-col justify-between bg-gray-700 p-6 text-center">
										<div className="flex-1">
											<a href={post.href} className="mt-2 block">
												<em className="text-xl font-semibold text-gray-200">
													{post.title}
												</em>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
