import { CheckIcon } from '@heroicons/react/24/outline';

export default function Features() {
  const missionText = `La Dirección de Salud es la instancia garante del Derecho a la Salud en el Distrito Capital; garantizar la prestación del servicio de atención integral en salud mediante la red hospitalaria, especializada y ambulatoria, mejorando la calidad de vida y promoviendo el fortalecimiento de los ciudadanos de la población del Distrito Capital.`;
  const visionText = `Contribuir al fortalecimiento del Sistema Público Nacional de Salud, ejerciendo su rectoría a través de una gestión eficiente y transparente, centrada en las necesidades de los usuarios, que dé respuesta oportuna, que incremente la calidad de vida de la población del Distrito Capital, que fomente la participación activa y protagónica de las comunidades, logrando la excelencia en el desempeño de las políticas sanitarias, siguiendo el plan nacional de salud. 2013-2019.`;

  return (
    <div className="bg-gray-500">
      <div className="mx-auto lg:mx-12 max-w-full py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div>
          <h2 className="text-lg font-semibold text-yellow-button">Misión y Visión</h2>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          {/* Mission Statement */}
          <div className="mb-10">
            <p className="text-xl text-white mt-4">{missionText}</p>
          </div>
          {/* Vision Statement */}
          <div className='mb-10'>
            <p className="text-xl text-white mt-4">{visionText}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
