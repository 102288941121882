import { Link } from "react-router-dom";

export default function CTA() {
    return (
      <div className="bg-blue-700">
        <div className="mx-auto lg:mx-12 max-w-full py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
            <span className="block">¿Te gustaría Registrarte?</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <Link
                to='/registro'
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-button px-5 py-3 text-base font-medium text-white hover:bg-blue-button transition duration-300 ease-in-out"
              >
                Regístrate
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
