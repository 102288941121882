import { connect } from "react-redux";
import Layout from "hocs/layouts/Layout";
import Navbar from "components/navigation/Navbar";
import Footer from "components/navigation/Footer";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { get_blog } from "redux/actions/blog/blog";
import moment from "moment";
import "moment/locale/es";
import DOMPurify from "dompurify";

function PostDetail({ get_blog, post }) {
	const params = useParams();
	const slug = params.slug;

	useEffect(() => {
		window.scrollTo(0, 0);
		get_blog(slug);
	}, []);

	return (
		<Layout>
			<header className="flex justify-center">
				<Navbar />
			</header>

			{post && post.slug === slug ? (
				<div className="pt-24 px-4 ">
					<div className="bg-gray-100/50 max-w-4xl px-4 mx-auto rounded-2xl">
						<div className="h-72 md:h-96">
							<img
								className="h-full w-full object-cover"
								src={post.thumbnail}
								alt="Banner principal del blog"
							/>
							<div
								className="bg-gray-200 mix-blend-multiply"
								aria-hidden="true"
							/>
						</div>
					</div>
					<div className="mx-auto max-w-4xl px-4 py-8">
						<h1 className="text-4xl font-bold tracking-tight text-black sm:text-5xl lg:text-6xl">
							{post.title}
						</h1>
						<div className="min-w-0 flex-1 p-4">
							<div className="">
								{post.categories.map((category, index) => (
									<span
										key={index}
										className="hover:text-orange-500 mx-1 font-medium text-gray-800 text-sm"
									>
										<Link to={`/category/${category.slug}`}>
											{category.name}
										</Link>
									</span>
								))}
								<span className="text-gray-300">&middot;</span>
								<span className="mt-2 ml-2 mr-1 font-medium text-gray-800 text-sm capitalize">
									{moment(post.published).format("MMM D, YYYY")}
								</span>{" "}
								<span className="text-gray-300">&middot;</span>
								<span className="mt-2 mx-2 font-medium text-gray-800 text-sm">
									{post.time_read} min de lectura
								</span>
								<p className="mt-4 text-lg font-regular text-gray-800 leading-8">
									{post.description}
								</p>
							</div>
						</div>
					</div>

					<div className="relative overflow-hidden bg-white py-4">
						<div className="relative px-4 sm:px-6 lg:px-8">
							<div className="prose prose-lg max-w-4xl prose-indigo mx-auto text-gray-500">
								<div
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(post.content),
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<>Loading</>
			)}
			<Footer />
		</Layout>
	);
}

const mapStateToProps = (state) => ({
	post: state.blog.post,
});

export default connect(mapStateToProps, {
	get_blog,
})(PostDetail);
