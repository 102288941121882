import Header from "components/directive/Header";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import CTA from "components/home/CTA";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Features from "components/directive/Features";
import hero_bg from "../../../assets/img/item-1.png";
import Meta from "components/navigation/Meta";

function Mujeres_Salud() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const features = [
		{
			description: 'Abogar por la equidad de oportunidades en el sector salud: Esto incluye luchar por la igualdad de acceso a la educación y capacitación, la promoción profesional justa y la eliminación de cualquier tipo de sesgo o discriminación en el ámbito laboral.'
		},
		{
			description: 'Promover la salud y el bienestar de todas las personas: Esto incluye abogar por políticas y programas que respondan a las necesidades de salud de toda la población, considerando las particularidades de cada grupo y promoviendo un enfoque integral de la salud.'
		},
		{
			description: 'Luchar contra cualquier forma de violencia en el sector salud: Esto incluye denunciar y abordar el acoso laboral, el hostigamiento sexual y otras formas de violencia que puedan afectar a cualquier persona en el ámbito sanitario.'
		},
		{
			description: 'Fortalecer el liderazgo femenino en todos los niveles: Esto incluye apoyar a las mujeres para que asuman puestos de liderazgo en clínicas locales, organizaciones nacionales e internacionales, y en la toma de decisiones políticas relacionadas con la salud.'
		},
		{
			description: 'Promover la investigación y el desarrollo de intervenciones sensibles a las necesidades de la población: Esto incluye garantizar que la investigación en salud considere las necesidades y experiencias de todas las personas y que los resultados se traduzcan en intervenciones efectivas para mejorar la salud de la población.'
		},
		{
			description: 'Educar e informar al público sobre la salud de todas las personas: Esto incluye aumentar la conciencia sobre los problemas de salud que afectan a la población, promover comportamientos y estilos de vida saludables y fomentar la participación activa de las personas en el cuidado de su propia salud.'
		},
		{
			description: 'Crear redes de apoyo y solidaridad entre las mujeres y personas del sector salud: Esto incluye brindar espacios para que las personas compartan sus experiencias, se apoyen mutuamente y trabajen juntas para lograr un cambio positivo en el sistema de salud.'
		}
	];

	const mision = "El Movimiento de Mujeres del Sector Salud tiene como objetivo principal la reivindicación de los derechos de la mujer, la inclusión y la igualdad de género en el sector salud. A través de la Revolución Bolivariana, se han impulsado importantes cambios sociopolíticos para lograr la verdadera liberación de la mujer, contribuyendo a la construcción de una robusta institucionalidad dirigida a la protección y acompañamiento de la mujer, además de importantes programas sociales para su dignificación.";

	return (
		<Layout>
			<Meta title="Movimiento de Mujeres del Sector Salud"/>
			<div className="relative bg-hero-pattern bg-cover bg-center bg-no-repeat">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<div data-scroll-section className="pt-20">
					<Header hero_bg={hero_bg} title={"Movimiento de Mujeres del Sector Salud"} mission={mision}/>
				</div>
				<CTA />
				<Features title={"Funciones Específicas"} features={features}/>
			</div>
			<Footer />
		</Layout>
	);
}

export default Mujeres_Salud;
