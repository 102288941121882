import { Typewriter } from "react-simple-typewriter";
import hero_bg from "../../assets/img/hero-bg.png";

const navigation = {
	social: [
		{
			name: "Facebook",
			href: "#",
			icon: (props) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
		{
			name: "Instagram",
			href: "#",
			icon: (props) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
		{
			name: "X",
			href: "#",
			icon: (props) => (
				<svg
					fill="currentColor"
					viewBox="0 0 1668.56 1221.19"
					style={{ enableBackground: "new 0 0 1668.56 1221.19" }}
					{...props}
				>
					<style type="text/css">
						{`.st0{stroke:#FFFFFF;stroke-miterlimit:10;}
            .st1{fill:#FFFFFF;}`}
					</style>
					<g>
						<circle className="st0" cx="834.28" cy="610.6" r="481.33" />
						<g id="layer1" transform="translate(52.390088,-25.058597)">
							<path
								id="path1009"
								className="st1"
								d="M485.39,356.79l230.07,307.62L483.94,914.52h52.11l202.7-218.98l163.77,218.98h177.32L836.82,589.6l215.5-232.81h-52.11L813.54,558.46L662.71,356.79H485.39z M562.02,395.17h81.46l359.72,480.97h-81.46L562.02,395.17z"
							/>
						</g>
					</g>
				</svg>
			),
		},
	],
};

function ProfileNotFound() {
	return (
		<main>
			<div
				className="relative px-6 lg:px-8 bg-hero-pattern bg-cover bg-center bg-no-repeat"
				id="seccion-1"
			>
				<div className="mx-auto max-w-full xl:mx-12 xl:pt-10 lg:pt-10 lg:pb-48 pb-10">
					<div className="block lg:flex lg:items-center lg:justify-center">
						<div className="flex justify-center order-1 lg:order-2">
							<img
								src={hero_bg}
								alt="hero background"
								className="w-full lg:w-auto lg:max-w-lg"
							/>
						</div>
						<div className="order-2 lg:order-1 lg:pr-10">
							<div className="order-2 lg:order-1">
								<h1 className="text-lg text-black font-semibold tracking-tight pb-3  sm:text-4xl">
									Usuario no encontrado por favor verifica el numero de
									documento que ingresaste.
								</h1>
							</div>
							<div className="absolute bottom-1 left-0 flex space-x-6 xl:mx-20 mx-6">
								{navigation.social.map((item) => (
									<div
										key={item.name}
										className="tooltip flex flex-col items-center"
									>
										<a
											href={item.href}
											className="text-gray-900 hover:text-gray-800 flex items-center space-x-2"
										>
											<item.icon className="h-8 w-8" aria-hidden="true" />
										</a>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default ProfileNotFound;
