import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({title}) => {
	return (
		<Helmet>
			<title>Salud Caracas | {title}</title>
			<meta
				name="description"
				content="Movimiento Por la PAZ y la VIDA , nuestro lema +PAZ +VIDA."
			/>
			<meta
				name="keywords"
				content="Movimiento Por la PAZ y la VIDA , nuestro lema +PAZ +VIDA."
			/>
			<meta name="robots" content="all" />
			<link rel="canonical" href="https://mpazyvida.com/" />
			<meta name="author" content="MPAZYVIDA" />
			<meta name="publisher" content="MPAZYVIDA" />

			{/* Social Media Tags */}
			<meta property="og:title" content="MPAZYVIDA | Caracas-Venezuela" />
			<meta
				property="og:description"
				content="Movimiento Por la PAZ y la VIDA , nuestro lema +PAZ +VIDA."
			/>
			<meta property="og:url" content="https://mpazyvida.com/" />
			<meta
				property="og:image"
				content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
			/>

			<meta name="twitter:title" content="MPAZYVIDA | Caracas-Venezuela" />
			<meta
				name="twitter:description"
				content="Movimiento Por la PAZ y la VIDA , nuestro lema +PAZ +VIDA."
			/>
			<meta
				name="twitter:image"
				content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
			/>
			<meta name="twitter:card" content="summary_large_image" />
		</Helmet>
	);
};

export default Meta;
