import { useState } from "react";
import { Link } from "react-router-dom";

export default function Search() {
	const [inputValue, setInputValue] = useState("");

	return (
		<div className="">
			<div className="mx-auto max-w-full py-12 px-4">
				<h2 className="text-2xl font-bold text-white mb-6 text-center">
					Buscar empleado
				</h2>
				<div className="flex flex-col items-center">
					<input
						type="text"
						placeholder="Numero de cedula"
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						className="rounded-md px-4 py-3 mb-4 text-gray-700 text-lg"
					/>
					<Link
						to={`/pacificador/${inputValue}`}
						className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
						replace={inputValue === ""}
					>
						Buscar
					</Link>
				</div>
			</div>
		</div>
	);
}
