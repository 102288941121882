import { Link } from "react-router-dom";

import img_23_enero from "../../assets/img/flags/parroquias/23DEENERO.png";
import img_altagracia from "../../assets/img/flags/parroquias/ALTAGRACIA.png";
import img_antimano from "../../assets/img/flags/parroquias/ANTIMANO.png";
import img_caricuao from "../../assets/img/flags/parroquias/CARICUAO.png";
import img_catedral from "../../assets/img/flags/parroquias/CATEDRAL.png";
import img_coche from "../../assets/img/flags/parroquias/COCHE.png";
import img_eljunquito from "../../assets/img/flags/parroquias/ELJUNQUITO.png";
import img_elparaiso from "../../assets/img/flags/parroquias/ELPARAISO.png";
import img_elrecreo from "../../assets/img/flags/parroquias/ELRECREO.png";
import img_elvalle from "../../assets/img/flags/parroquias/ELVALLE.png";
import img_la_candelaria from "../../assets/img/flags/parroquias/LACANDELARIA.png";
import img_la_pastora from "../../assets/img/flags/parroquias/LAPASTORA.png";
import img_la_vega from "../../assets/img/flags/parroquias/LAVEGA.png";
import img_macarao from "../../assets/img/flags/parroquias/MACARAO.png";
import img_san_agustin from "../../assets/img/flags/parroquias/SANAGUSTIN.png";
import img_san_bernardino from "../../assets/img/flags/parroquias/SANBERNARDINO.png";
import img_san_jose from "../../assets/img/flags/parroquias/SANJOSE.png";
import img_san_juan from "../../assets/img/flags/parroquias/SANJUAN.png";
import img_santa_rosalia from "../../assets/img/flags/parroquias/SANTAROSALIA.png";
import img_santa_teresa from "../../assets/img/flags/parroquias/SANTATERESA.png";
import img_sucre from "../../assets/img/flags/parroquias/SUCRE.png";

export default function LogoCloud() {
	return (
		<div className="">
			<div className="mx-auto max-w-full py-12 px-4 sm:px-6 lg:px-8">
				<h2 className="mb-8 text-3xl font-bold tracking-tight text-yellow-button text-center">
					NOS ENCONTRAMOS
				</h2>
				<div className="grid grid-cols-2 gap-4 sm:gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_23_enero} alt="23 de Enero" />
							<h3 className="font-bold text-gray-200">23 de Enero</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">El 23 de Enero:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la
								ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_altagracia} alt="Altagracia" />
							<h3 className="font-bold text-gray-200">Altagracia</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">Altagracia:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la
								ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_antimano} alt="Antímano" />
							<h3 className="font-bold text-gray-200">Antímano</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">Antímano:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la
								ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_caricuao} alt="Caricuao" />
							<h3 className="font-bold text-gray-200">Caricuao</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">Caricuao:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la
								ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_catedral} alt="Catedral" />
							<h3 className="font-bold text-gray-200">Catedral</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">Catedral:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la
								ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_coche} alt="Coche" />
							<h3 className="font-bold text-gray-200">Coche</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">Coche:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la
								ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_eljunquito} alt="El Junquito" />
							<h3 className="font-bold text-gray-200">El Junquito</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">El Junquito:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la
								ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_elparaiso} alt="El Paraíso" />
							<h3 className="font-bold text-gray-200">El Paraíso</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">El Paraíso:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la
								ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_elrecreo} alt="El Recreo" />
							<h3 className="font-bold text-gray-200">El Recreo</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-0 group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">El Recreo:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la
								ciudad.
							</div>
						</div>
						</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_elvalle} alt="El Valle" />
							<h3 className="font-bold text-gray-200">El Valle</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">El Valle:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
						</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_la_candelaria} alt="La Candelaria" />
							<h3 className="font-bold text-gray-200">La Candelaria</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">La Candelaria:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_la_pastora} alt="La Pastora" />
							<h3 className="font-bold text-gray-200">La Pastora</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">La Pastora:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_la_vega} alt="La Vega" />
							<h3 className="font-bold text-gray-200">La Vega</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">La Vega:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_macarao} alt="Macarao" />
							<h3 className="font-bold text-gray-200">Macarao</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">Macarao:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_san_agustin} alt="San Agustín" />
							<h3 className="font-bold text-gray-200">San Agustín</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">San Agustín:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_san_bernardino} alt="San Bernardino" />
							<h3 className="font-bold text-gray-200">San Bernardino</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">San Bernardino:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_san_jose} alt="San José" />
							<h3 className="font-bold text-gray-200">San José</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">San José:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_san_juan} alt="San Juan" />
							<h3 className="font-bold text-gray-200">San Juan</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">San Juan:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_santa_rosalia} alt="Santa Rosalía" />
							<h3 className="font-bold text-gray-200">Santa Rosalía</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">Santa Rosalía:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>

					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_santa_teresa} alt="Santa Teresa" />
							<h3 className="font-bold text-gray-200">Santa Teresa</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">Santa Teresa:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
					</div>
					<div className="bg-blue-700 hover:bg-gray-700 transition duration-150 ease-linear py-5 rounded-xl relative group overflow-hidden">
						<div className="flex flex-col items-center justify-center">
							<img className="h-24" src={img_sucre} alt="Sucre" />
							<h3 className="font-bold text-gray-200">Sucre</h3>
						</div>
						<div className="p-5 w-full top-[100%] left-[100%] h-full absolute group-hover:top-0 group-hover:left-[0] group-hover:z-10 group-hover:bg-black group-hover:bg-opacity-60 group-hover:backdrop-blur-md transition duration-150 ease-linear text-gray-200 flex flex-col justify-center">
							<div className="text-xs font-bold">Sucre:</div>
							<div className="text-xs">
								Es una parroquia de Caracas, Venezuela, ubicada al oeste de la ciudad.
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}
