import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get_profile } from "redux/actions/profile/profile";
import moment from "moment";
import Layout from "hocs/layouts/Layout";
import Navbar from "components/navigation/Navbar";
import Footer from "components/navigation/Footer";

import rojo from "../../assets/img/banners/rojo.png";
import amarillo from "../../assets/img/banners/amarillo.png";
import verde from "../../assets/img/banners/verde.png";
import azul from "../../assets/img/banners/azul.png";
import naranja from "../../assets/img/banners/naranja.png";
import negro from "../../assets/img/banners/negro.png";
import Meta from "components/navigation/Meta";
import Header from "components/personal/Header";
import ProfileNotFound from "components/personal/ProfileNotFound";

function Profile({ get_profile, profile }) {
	const params = useParams();
	const nuip = params.nuip;
	const [bgColor, setBgColor] = useState("bg-gray-700");

	// creame el use efect para llamar al get profile pasando nuip comovalor

	useEffect(() => {
		get_profile(nuip);
	}, [nuip]);
	useEffect(() => {
		if (profile) {
			switch (profile.rango) {
				case "Superior":
					setBgColor("bg-red-500");
					break;
				case "Tactico":
					setBgColor("bg-blue-500");
					break;
				case "Auditor-Seguridad":
					setBgColor("bg-yellow-500");
					break;
				case "Regiones":
					setBgColor("bg-green-500");
					break;
				case "Usuario":
					setBgColor("bg-orange-500");
					break;
				default:
					setBgColor("bg-gray-500");
			}
		}
	}, [profile]);

	let bannerImage;
	if (profile) {
		switch (profile.rango) {
			case "Superior":
				bannerImage = rojo;
				break;
			case "Tactico":
				bannerImage = azul;
				break;
			case "Auditor-Seguridad":
				bannerImage = amarillo;
				break;
			case "Regiones":
				bannerImage = verde;
				break;
			case "Usuario":
				bannerImage = naranja;
				break;
			default:
				bannerImage = negro;
		}
	}

	// Usa profile para mostrar los datos del perfil
	const profileData = profile; // Asumiendo que 'profile' contiene los datos cargados

	const [isEnlarged, setIsEnlarged] = useState(false);

	const handleImageClick = () => {
		setIsEnlarged(true);
	};

	const handleCloseEnlargedImage = () => {
		setIsEnlarged(false);
	};

	return (
		<Layout>
			<div className="">
				<header className="flex justify-center">
					<Navbar />
				</header>
				<Meta title="Perfil del Empleado" />

					{profileData ? (
						<div className="container mx-auto max-w-3xl relative mt-28 mb-12">
							<div className="mb-2">
								<img
									src={bannerImage}
									className="rounded-xl"
									alt="Banner profile"
								/>
							</div>
							<div className="flex relative">
								<div className="flex md:flex-row md:justify-between px-4 py-3 shadow-lg w-full rounded-xl md:shadow-none bg-gray-700">
									<div className="profile-data flex flex-row md:items-center gap-4">
										<div className="relative drop-shadow-md">
											<div
												className={`p-1 rounded-full overflow-hidden w-20 h-20 md:w-32 md:h-32 flex justify-center items-center ${bgColor}`}
											>
												{profileData?.img_profile && (
													<img
														src={`${process.env.REACT_APP_API_URL}${profileData.img_profile}`}
														alt="User profile"
														onClick={handleImageClick}
													/>
												)}
											</div>
											{isEnlarged && (
												<div
													className="fixed inset-0"
													onClick={handleCloseEnlargedImage}
												/>
											)}
											{isEnlarged && (
												<div className="fixed inset-20 md:-top-40 md:left-44 w-52 h-52 md:h-96 md:w-96">
													<img
														src={`${process.env.REACT_APP_API_URL}${profileData.img_profile}`}
														className="h-full w-full justify-center flex m-auto"
														alt="Enlarged user profile"
													/>
												</div>
											)}
										</div>
										<div className="flex flex-col md:items-start">
											<h2 className="font-bold text-sm md:text-lg uppercase mb-2 text-white">
												{profileData.first_name + " " + profileData.last_name}
											</h2>
											<h3 className="text-xs md:text-sm uppercase text-white">
												<div className="font-bold">CÉDULA DE IDENTIDAD:</div>{" "}
												{profileData.nuip}
											</h3>
											<h4 className="text-xs md:text-sm uppercase my-2 text-white">
												<div className="font-bold">CÓDIGO DE TRABAJADOR:</div>{" "}
												{profileData.worked_code}
											</h4>
											<h4 className="text-xs md:text-sm uppercase my-2 text-white">
												<div className="font-bold">SERIAL:</div>{" "}
												{profileData.serial}
											</h4>
											<h4 className="text-xs md:text-sm uppercase my-2 text-white">
												<div className="font-bold">FECHA DE NACIMIENTO:</div>{" "}
												{profileData.birth_date}
											</h4>
											<h4 className="text-xs md:text-sm uppercase my-2 text-white">
												<div className="font-bold">ADSCRIPCIÓN:</div>{" "}
												{profileData.ascription}
											</h4>
											<h4 className="text-xs md:text-sm uppercase my-2 text-white">
												<div className="font-bold">CARGO:</div>{" "}
												{profileData.charge}
											</h4>
											<h4 className="text-xs md:text-sm uppercase my-2 text-white">
												<div className="font-bold">
													RESPONSABILIDADES EXTRAORDINARIAS:
												</div>{" "}
												{profileData.extraordinary_responsabilities}
											</h4>
											<h4 className="text-xs md:text-sm uppercase my-2 text-white">
												<div className="font-bold">TIPIFICACIÓN:</div>{" "}
												{profileData.criminalization === true
													? "Nómina"
													: "Voluntario"}
											</h4>
										</div>
									</div>
									<div className="flex md:justify-end">
										<div>
											<span className="font-bold text-sm text-white">
												Registrado:{" "}
											</span>
											<span className="text-sm  text-white">
												{moment(profileData.created).format("DD/MM/YYYY")}
											</span>
										</div>
									</div>
								</div>
							</div>
							</div>
					) : (
					<div className="pt-28">

						<ProfileNotFound/>
					</div>
					)}

				<Footer />
			</div>
		</Layout>
	);
}

const mapStateToProps = (state) => ({
	profile: state.profile.profile,
});

export default connect(mapStateToProps, { get_profile })(Profile);
