import { CheckIcon } from '@heroicons/react/24/outline'


export default function Features({title,features}) {
  return (
    <div className="">
      <div className="mx-auto lg:mx-12 max-w-full py-16 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
        <div className="lg:col-span-3">
          <h2 className="text-lg font-semibold text-yellow-button">Funciones</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-black">{title}</p>
        </div>
        <dl className="mt-12 lg:mt-0 lg:col-span-3 grid grid-cols-3 gap-x-6 gap-y-10">
          {features.map((feature, index) => (
            <div key={index} className="relative">
              <dt>
                <CheckIcon className="absolute h-8 w-6 text-green-600" aria-hidden="true" />
                <p className="mt-2 ml-9 text-base text-black">
                  {index + 1}. {feature.description}
                </p>
              </dt>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
